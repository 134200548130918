<template>
<div class="mt-auto pb-3 pb-sm-0 w-100">
  <b-button :disabled="!value" type="submit" variant="light-success" size="sm" class="btn-block d-block " @click="$emit('toggle')">
    <span class="svg-icon svg-icon-success svg-icon-sm">
      <slot name="icon" />
    </span>
    {{ $t('FORM.SUBMIT') }}
  </b-button>
  <div class="fake-element">
    <!--      IOS fix-->
  </div>
</div>
</template>
<script>
export default {
  name: 'SubmitButton',
  props: {
    value: {
      type: Boolean,
    },
  },
}
</script>

<style scoped>

</style>
