<template>
<b-form class="form d-flex flex-column h-100" @submit.stop.prevent="$emit('submit', 'submit')">
  <div class="form-container w-100">
    <slot name="form" />
  </div>
  <submit-button v-model="disabled">
    <template #icon>
      <inline-svg src="/media/svg/icons/Text/Filter.svg" class="danger" />
    </template>
  </submit-button>
</b-form>
</template>

<script>
import SubmitButton from '../../forms-items/quick-panel/SubmitButton'

export default {
  name: 'CriteriaForm',
  components: { SubmitButton },
  props: {
    canSubmit: {
      type: Boolean, // TODO: Mutate prop, need to find out
    },
  },
  data() {
    return {
      disabled: false,
    }
  },
  watch: {
    canSubmit: {
      immediate: true,
      handler(val) {
        this.disabled = val
      },
    },
  },
}
</script>

<style scoped>

</style>
