import qs from 'qs'

export default {
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    async apply() {
      this.loading = true
      if (this.$v.form.$touch() && this.$v.form.$invalid) {
        this.loading = false
        return
      }
      const formQueryString = this.toQueryString(JSON.parse(JSON.stringify(this.form))) // Hook to deep serialize data prop
      // history.replaceState(null, null, `?${formQueryString}`) // TODO: ???
      localStorage.setItem(this.$options.name, formQueryString)
      this.$emit('populate', this.criteria)
      this.loading = false
    },
    toQueryString(obj) {
      return qs.stringify(obj)
    },
    toObject(obj) {
      return qs.parse(obj)
    },
  },
  computed: {
    canSubmit() {
      return !this.$v.form.$invalid && !this.loading
    },
    criteria() { // Transform form into api request criteria
      const criteria = { ...this.form }
      const finalCriteria = {}
      if (Object.keys(this.normalizers).length) { // Filter stored criteria to suite currently defined form (For changes between builds)
        const normalizers = this.$_dot().dot(this.normalizers)
        Object.keys(normalizers).forEach((path) => {
          this.$_set(finalCriteria, path, normalizers[path](this.$_get(criteria, path)))
        })
      }

      return finalCriteria
    },
    prevCriteria() { // Previously defined stored criteria
      return this.toObject(localStorage.getItem(this.$options.name) || '')
    },
  },
  async created() {
    let predef = this.$_dot().dot(this.prevCriteria)
    const query = this.$_dot().dot(this.toObject(window.location.search.substring(1)))
    if (Object.keys(query).length) { // Query string is preferred criteria to process
      predef = query
    }

    if (Object.keys(predef).length) { // If nothing defined in booth query & local storage, do nothing
      Object.keys(predef).forEach((path) => {
        this.$_set(this.form, path, predef[path])
      })
      await this.apply()
    }
  },
}
