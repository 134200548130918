<!--suppress HtmlUnknownTarget -->
<template>
<a href="#" @click.prevent="download" class="btn-clean btn">
  <span class="svg-icon svg-icon-success svg-icon-lg">
    <slot />
  </span>
</a>
</template>

<script>
export default {
  name: 'DownloadButton',
  props: {
    value: {
      type: Boolean,
    },
    path: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: 'xlsx',
    },
    fileName: {
      type: String,
      default: 'report',
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    download() {
      this.$emit('input', true)
      const params = { ...this.params }
      params.format = this.format
      this.$http.request(this.path, params, {
        responseType: 'blob',
        headers: { Accept: 'application/octet-stream' },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${this.fileName}.${this.format}`)
        document.body.appendChild(link)
        link.click()
        this.$emit('input', false)
      }).catch((err) => {
        console.log(err)
        this.$bvToast.toast(this.$t('TOAST.ERROR'), {
          title: 'Error',
          variant: 'danger',
          autoHideDelay: 2000,
          solid: true,
        })
      }).finally(() => this.$emit('input', false))
    },
  },
}
</script>
